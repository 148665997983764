import { styled } from "styled-components";
import { customPalette } from "../../../../config/theme/theme";

export const ButtonNav = styled.a`
  text-decoration: none;
  height: fit-content;
  width: fit-content;
  border-radius: 3px;
  text-align: center;
  position: relative;
  min-width: 100px;
  cursor: pointer;
  line-height: 1;
  display: block;
  outline: none;
  padding: 5px;
  color: #fff;

  &:hover {
    background: white;
    color: ${customPalette.primaryColor};
  }
`;
