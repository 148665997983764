import React from "react";
import { ContainerSignIn } from "./sign-in.styles";
import LogoIMG from "../../assets/img/logo.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthForm, AuthSchema } from "../../core/models/auth-model";
import axios from "axios";
import swal from "sweetalert";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { APP_CONSTANS } from "../../constants/app";
import { useNavigate } from "react-router-dom";

interface ISignInResponse {
  code: string;
  message: string;
  data: string;
}

const SignIn: React.FC = () => {
  const navigate = useNavigate();

  const methods = useForm<AuthForm>({
    resolver: yupResolver(AuthSchema),
    defaultValues: {
      pass: "",
    },
  });

  const {
    handleSubmit: submitWrapper,
    formState: { errors },
    register,
  } = methods;

  const handleSubmit = React.useCallback((data: any) => {
    axios({
      url: `https://email-api.anticoncepcionquesepega.com/auth/engyno1`,
      method: "POST",
      headers: {
        ContentType: "application/json",
        Accept: "application/json",
        AccessControlAllowOrigin: "*",
        mode: "no-cors",
      },
      data: {
        password: data.pass,
      },
    })
      .then((result: any) => {
        const data: ISignInResponse = result.data as ISignInResponse;
        if (data.code == "202") {
          const tokenEncrypt = CryptoJS.AES.encrypt(
            APP_CONSTANS.KEYENGYNO,
            APP_CONSTANS.KAEG1CT
          ).toString();
          const expiryTime = new Date(new Date().getTime() + 180 * 60 * 1000);
          Cookies.set(APP_CONSTANS.KAEG1CT, tokenEncrypt, {
            expires: expiryTime,
          });
          navigate("/");
        } else {
          swal("Error", "Hubo un error al autenticarse", "error");
        }
      })
      .catch((err) => {
        swal(
          "Error",
          "No hemos podido enviar tu solicitud, por favor intentalo nuevamente",
          "error"
        );
      });
  }, []);

  return (
    <ContainerSignIn>
      <div>
        <img src={LogoIMG} />
      </div>
      <form>
        <div>
          <label>Contraseña</label>
          <input type="password" {...register("pass")} />
        </div>
        <button onClick={submitWrapper(handleSubmit)}>Acceder</button>
      </form>
    </ContainerSignIn>
  );
};

export default SignIn;
