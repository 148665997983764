import React from 'react'
import { ContainerImage, ContainerInfo, ContainerText } from './section-info-styles'
import { Box, Typography } from '@mui/material'
import SiuIMG from '../../../../assets/img/img_02.png'
import WoomanIMG from '../../../../assets/img/img_07.png'
import DescriptionIMG from '../../../../assets/img/img_08.png'
import SectionMax from '../../../../components/section-max/section-max'

const SectionInfo: React.FC = () => {
  return (
     <SectionMax customStyles='padding: 0;'>
        <ContainerInfo  id="engyno">
            <ContainerText>
                <Typography variant="h2" component="h2">
                ¿Qué es Engyno<sup>®</sup>1?
                </Typography>
              <Typography variant="body2" component="p">
              Engyno<sup>®</sup>1 es un sistema intrauterino (SIU) que libera levonorgestrel, diseñado para ofrecer no solo protección anticonceptiva, sino también para ayudar a mujeres con menorragia idiopática. Con una duración efectiva de hasta 6 años, proporciona una solución a largo plazo que puedes confiar.
              </Typography>
            </ContainerText>
            <ContainerImage>
              <Box
                component={"img"}
                alt="Engyno1"
                src={WoomanIMG}
                />
              <Box
                component={"img"}
                alt="Engyno1"
                src={DescriptionIMG}
                />
              <Box
                component={"img"}
                alt="Engyno1"
                src={SiuIMG}
              />
            </ContainerImage>
        </ContainerInfo>
    </SectionMax>
  )
}

export default SectionInfo