import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerBenefits = styled.div<{ imgBack: string }>`
background: radial-gradient(circle, rgba(255,255,255,1) -40%, rgba(255,224,177,1) 100%);
// background-image: url(${p => p.imgBack});
// background-blend-mode: color-burn;
// background-repeat: no-repeat;
// background-position: 0% 58%;
// background-size: 200%;
position: relative;
width: 100%;
display: grid;
grid-template-columns: 1fr 1200px 1fr;


> img {
    width: 100%;
    height: 100%;
}

${breakpoints.laptopM}{
    display: flex;
}

${breakpoints.phoneL}{
    padding: 0 10px;
}

`

export const ContainerStylesMax = `
flex-direction: row;
position: relative;
display: flex;
padding: 0;

${breakpoints.tabletM}{
    flex-direction: column;
}
`

export const ContainerLeft = styled.div`
width: 65%;
padding: 40px 0;


> h2{
    font-weight: 600;
    font-size: 40px;
    color: #F06125;
    width: 100%;
    
    ${breakpoints.laptopM}{
        margin-bottom: 20px;
    }
    
    ${breakpoints.tabletL}{
        font-size: 30px;
    }
  }

  > p {
    font-size: 24px;
    font-weight: 500;
    margin-top: 25px;
  }

${breakpoints.laptopM}{
    padding: 40px 20px;
    width: 100%
}
`

export const ContainerRight = styled.div`
justify-content: flex-end;
position: relative;
display: flex;
width: 35%;

> img{
    position: absolute;
    max-width: 350px;
    height: 100%;
    width: 100%;
    z-index: 1;

    ${breakpoints.tabletM}{
        position: relative;
        flex-wrap: wrap;
    }
}

${breakpoints.tabletM}{
    width: 100%
}
`

export const FloatBackground = styled.div`
background: linear-gradient(0deg, rgba(249,162,69,1) 0%, rgba(255,255,255,0.2) 100%);
clip-path: polygon(80% 0, 100% 0, 100% 100%, 0 100%);
position: absolute;
height: 100%;
width: 220px;
z-index: 0;
top: 0;

${breakpoints.tabletM}{
    width: 80%
}
`

export const DividerLeft = styled.div`
width: 100%;

${breakpoints.laptopM}{
    display: none;
}
`

export const DividerRight = styled.div`
background: linear-gradient(0deg, rgba(249,162,69,1) 0%, rgba(255,255,255,0.2) 100%);
height: 100%;
width: 100%;

${breakpoints.laptopM}{
    display: none;
}
`

export const ContainerList = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`

export const ItemList = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

> div:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;

    > img {
        width: 50px;

        ${breakpoints.tabletL}{
            width: 40px;
        }

        ${breakpoints.phoneL}{
            width: 30px;
        }
    }
    
  > h5{
    font-weight: 600;
    color: #F06125;
    width: 100%;
    
    > span {
        color: black;
        font-weight: 400;
    }

    ${breakpoints.tabletL}{
        font-size: 20px;
    }

    ${breakpoints.phoneL}{
        font-size: 18px;
    }
  }
}

> div:nth-child(2){
    > div {
        place-content: center;
        background: #F06125;
        border-radius: 50%;
        height: 100px;
        display: grid;
        padding: 15px;
        width: 100px;

        > img {
            width: 100%;
            height: auto;
        }

        ${breakpoints.tabletL}{
            width: 80px;
            height: 80px;
        }

        ${breakpoints.phoneL}{
            padding: 10px;
            height: 60px;
            width: 60px;
        }
    }
}

`