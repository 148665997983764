import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerFaqs = styled.div`
position: relative;
width: 100%;
padding: 60px 0;

${breakpoints.laptopM}{
    padding: 60px 30px;
}
`

export const ContainerLeft = styled.div`
width: 100%;

> h2{
    font-weight: 700;
    font-size: 50px;
    color: #F06125;
    width: 100%;
    margin: 0;
    padding: 0;

    ${breakpoints.laptopM}{
        font-size: 35px;
    }
    
    ${breakpoints.tabletL}{
      font-size: 30px;
    }
}
`

export const ContainerList = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
margin-top: 20px;
`

export const ItemList = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

> div:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;

    > img {
        width: 50px;
        
        ${breakpoints.phoneL}{
            display: none;
        }
    }
    
    ${breakpoints.phoneL}{
        flex-direction: column;
    }
}

> div div {

    > h5{
        font-weight: bold;
        color: #F06125;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    > p {
        font-size: 20px;
        font-weight: 500;
        margin-top: 10px;
        text-align: justify;

        ${breakpoints.tabletL}{
          font-size: 18px;
        }
        ${breakpoints.tabletM}{
          font-size: 16px;
        }
    }
    
    
    > div{
        align-items: center;
        display: flex;
        gap: 10px;
        
        > img {
            display: none;
            max-width: 30px;
            height: auto;
            
            ${breakpoints.phoneL}{
              display: block;
            }
        }
    }
}
`