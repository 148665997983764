import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerInfo = styled.div`
position: relative;
width: 100%;
padding: 0 0 60px;

${breakpoints.laptopM}{
  padding: 0 20px 60px;
}
`

export const ContainerText = styled.div`
position: absolute;
width: 60%;
height: fit-content;
z-index: 1;

> h2{
    font-weight: 500;
    font-size: 40px;
    color: #F06125;
    width: 100%;
    
    > sup {
        vertical-align: top;
        font-size: 20px;
    }
  }

  > p {
    font-size: 24px;
    font-weight: 500;
    margin-top: 25px;

    > sup{
      vertical-align: top;
      font-size: 15px;
    }
  }


  ${breakpoints.tabletL}{
    > h2{
      font-size: 30px
    }
    >p{
      font-size: 18px;
    }
  }
  ${breakpoints.tabletM}{
    position: relative;
    width: 100%;
    padding: 10px;
    margin-bottom: 30px;

    > h2{
      font-size: 22px;
    }
    > p{
      font-size: 18px;
      text-align: justify;
    }
  }
`

export const ContainerImage = styled.div`
position: relative;

> img:nth-child(1), img:nth-child(2){
    display: none;
    height: 100%;
    width: 100%;

    ${breakpoints.tabletM}{
        display: block;
    }
}

> img:nth-child(1){
    height: auto;
    width: 100%;
    max-width: 220px;
    position: absolute;
    left: 20px;
    top: -20px;

    ${breakpoints.phoneL}{
        display: none;
    }
}

> img:nth-child(2){
    height: 100%;
    width: 100%;
}

> img:nth-child(3){
    display: block;
    height: 100%;
    width: 100%;

    ${breakpoints.tabletM}{
        display: none;
    }
}

${breakpoints.phoneL}{
  padding: 0 10px;
}
`