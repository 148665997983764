import React from "react";
import HeaderIMG from "../../../../assets/img/img_01.png";
import {
  ContainerDescription,
  ContainerHeader,
  ContainerHeaderLeft,
  ContainerHeaderRight,
  ContainerText
} from "./section-header-styles";
import { Box, Typography } from "@mui/material";
import SectionMax from "../../../../components/section-max/section-max";

const SectionHeader = () => {
  return (
    <SectionMax customStyles="padding: 0;">
      <ContainerHeader>
        <ContainerHeaderLeft>
          <ContainerText>
              <Typography variant="h2" component="h2">
              Descubre <span>Engyno<sup>®</sup>1</span>: Tu Aliado en la Salud y Bienestar Femenino
              </Typography>
            <Typography variant="body2" component="p">
            Conoce cómo Engyno<sup>®</sup>1 te ofrece protección anticonceptiva y tratamiento para la menorragia idiopática, con un solo dispositivo.
            </Typography>
          </ContainerText>
        </ContainerHeaderLeft>
        <ContainerHeaderRight>
            <Box
              component={"img"}
              alt="Engyno1"
              src={HeaderIMG}
              />
        </ContainerHeaderRight>
      </ContainerHeader>
    </SectionMax>
  );
};

export default SectionHeader;
