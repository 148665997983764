import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerBenefits = styled.div<{ imgBack: string }>`
background: radial-gradient(circle, rgba(255,255,255,1) -40%, rgba(255,224,177,1) 100%);
// background-image: url(${p => p.imgBack});
// background-blend-mode: color-burn;
// background-repeat: no-repeat;
// background-position: 0% 58%;
// background-size: 200%;
position: relative;
width: 100%;
display: grid;
grid-template-columns: 1fr 1200px 1fr;


> img {
    width: 100%;
    height: 100%;
}

${breakpoints.laptopM}{
    display: flex;
    padding: 0 30px;
}

`

export const ContainerStylesMax = `
flex-direction: row;
position: relative;
display: flex;
padding: 0;

${breakpoints.tabletM}{
    flex-direction: column;
}
`

export const ContainerLeft = styled.div`
justify-content: flex-start;
position: relative;
display: flex;
width: 35%;

> img{
    position: absolute;
    max-width: 380px;
    height: 100%;
    width: 100%;
    z-index: 1;

    ${breakpoints.tabletM}{
        position: relative;
        max-width: 300px;
    }
}

${breakpoints.tabletM}{
    width: 100%;
}
`

export const ContainerRight = styled.div`
padding: 40px 20px;
width: 65%;

> div {
    width: 100%;

    > img {
        width: 100%;
    }

    > h2{
        text-align: justify;
        font-weight: 600;
        font-size: 28px;
        margin-top: 20px;
        color: #F06125;
        width: 100%;
        
        > sup {
            font-size: 15px;
        }
        
        ${breakpoints.laptopM}{
            margin-bottom: 20px;
        }
        
        ${breakpoints.tabletL}{
            font-size: 18px;
        }
        
        ${breakpoints.tabletM}{
            font-size: 15px;
        }
    }
}

${breakpoints.laptopM}{
    padding: 40px 0px;
}

${breakpoints.tabletM}{
    width: 100%;
}
`

export const FloatBackground = styled.div`
background: linear-gradient(0deg, rgba(249,162,69,1) 0%, rgba(255,255,255,0.2) 100%);
clip-path: polygon(0 0, 20% 0, 100% 100%, 0 100%);
position: absolute;
height: 100%;
width: 220px;
z-index: 0;
top: 0;

${breakpoints.tabletM}{
    width: 100%;
    max-width: 400px;
}
`

export const DividerLeft = styled.div`
background: linear-gradient(0deg, rgba(249,162,69,1) 0%, rgba(255,255,255,0.2) 100%);
height: 100%;
width: 100%;

${breakpoints.laptopM}{
    display: none;
}
`

export const DividerRight = styled.div`
width: 100%;

${breakpoints.laptopM}{
    display: none;
}
`

export const ContainerButtons = styled.div`
justify-content: center;
align-items: center;
margin-top: 30px;
display: flex;
gap: 40px;

> button {
    background: transparent;
    border-radius: 20px;
    border-radius: 50px;
    position: relative;
    width: fit-content;
    text-align: center;
    padding: 10px 5px;
    font-weight: 800;
    cursor: pointer;
    font-size: 16px;
    color: #F06125;
    outline: none;
    width: 200px;
    border: 0;
    
    > img {
        position: absolute;
        max-width: 200px;
        margin: auto;
        width: 100%;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

}

> button:hover{
    background: #F06125;
    color: white;
}

${breakpoints.phoneL}{
    flex-direction: column;
}
`