import React from "react";
import {
  BottomNavbarMobile,
  ContainerBar,
  ContainerGeneral,
  ContainerLeft,
  ContainerNavLinks,
  ContainerNavbar,
  ContainerRight,
  FloatColor,
} from "./navbar-styles";
import { Box } from "@mui/material";
import SectionMax from "../section-max/section-max";
import ItemNav from "./components/item-nav/item-nav";
import { Bars } from "@styled-icons/fa-solid/Bars";
import ItemNavMobile from "./components/item-nav-mobile/item-nav-mobile";
import ParchesLogoIMG from "../../assets/img/logo.png";
import RompecabezasIMG from "../../assets/img/rompecabezas-header.png";

const Navbar: React.FC = () => {
  const [isActiveMenu, setIsActiveMenu] = React.useState(false);

  const handleOpenMenu = () => setIsActiveMenu(!isActiveMenu);

  return (
    <>
    <ContainerNavbar id="home">
      <SectionMax customStyles="position: relative;">
        <ContainerGeneral>
          <ContainerLeft>
            <Box
              component="img"
              alt="Logo"
              src={ParchesLogoIMG}
            />
          </ContainerLeft>
          <ContainerRight imgBack={RompecabezasIMG}>
            <ContainerNavLinks>
              <ItemNav text="Home" to="home" />
              <ItemNav text="Engyno" to="engyno" />
              <ItemNav text="¿Cómo funciona?" to="funcionalidad" />
              <ItemNav text="Testimonios" to="testimonios" />
              <ItemNav text="FAQs" to="preguntas-frecuentes" />
            </ContainerNavLinks>
            <ContainerBar onClick={handleOpenMenu}>
              <Bars />
            </ContainerBar>
          </ContainerRight>
        </ContainerGeneral>
      </SectionMax>
      <BottomNavbarMobile isActive={isActiveMenu}>
          <ItemNavMobile text="Home" to="home" />
          <ItemNavMobile text="Engyno" to="engyno" />
          <ItemNavMobile text="¿Cómo funciona?" to="funcionalidad" />
          <ItemNavMobile text="Testimonios" to="testimonios" />
          <ItemNavMobile
            text="FAQs"
            to="preguntas-frecuentes"
          />
        </BottomNavbarMobile>
      <FloatColor imgBack={RompecabezasIMG}/>
    </ContainerNavbar>
    </>
  );
};

export default Navbar;
