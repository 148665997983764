import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerHeader = styled.div`
  flex-direction: row;
  column-gap: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 60px 0;
  
  ${breakpoints.laptopM} {
    padding: 60px 20px;
  }

  ${breakpoints.tabletM} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerText = styled.div`
  width: 100%;

  > h2{
    font-weight: 500;
    font-size: 40px;
    color: #F06125;
    width: 100%;
    
    > span {
      font-weight: 800;
      
      > sup{
        vertical-align: top;
        font-size: 25px;

        ${breakpoints.tabletL}{
          font-size: 20px;
        }
        
        ${breakpoints.tabletM}{
          font-size: 15px;
        }
      }
    }
  }

  > p {
    font-size: 25px;
    font-weight: 500;
    margin-top: 30px;

    > sup{
      font-size: 15px;
    }
  }


  ${breakpoints.tabletL}{
    > h2{
      font-size: 30px
    }
    >p{
      font-size: 20px;
    }
  }
  ${breakpoints.tabletM}{
    > h2{
      font-size: 22px;
    }
    > p{
      font-size: 18px;
    }
  }
`;

export const ContainerDescription = styled.div`
  width: 100%;
  padding: 30px 0 10px;

  > p {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: normal;
  }
  > p:nth-child(1) {
    color: #e9427c;
    font-weight: bold;
  }
  > p:nth-child(2) span {
    font-weight: bold;
  }
  > p:nth-child(3) {
    margin-top: 20px;

    > span:nth-child(1) {
      color: #e9427c;
      font-weight: bold;
    }
    > span:nth-child(2) {
      color: #ff9700;
      font-weight: bold;
    }
    > span:nth-child(3) {
      color: #ae00ff;
      font-weight: bold;
    }
  }

  ${breakpoints.tabletL} {
    text-align: justify;
    padding: 0 10px;
  }
`;

export const ContainerHeaderLeft = styled.div`
  width: 70%;
  height: 100%;
  box-sizing: border-box;

  ${breakpoints.tabletM}{
    width: 100%;
    padding: 10px;
  }
  `;
  
  export const ContainerHeaderRight = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: right;
  
  > img {
    width: 100%;
    height: 100%;
    max-width: 250px;
    object-fit: cover;
  }
  
  ${breakpoints.tabletL} {
    justify-content: center;
  }
  
  ${breakpoints.tabletM}{
    width: 100%;
  }
`;