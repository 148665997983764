import React from 'react'
import SectionMax from '../../../../components/section-max/section-max'
import { Box, Typography } from '@mui/material'
import { ContainerDescription, ContainerPreview } from './section-description-styles'
import VideoMP4 from '../../../../assets/video/video_engyno.mp4'
import VideoPreview from '../../../../assets/img/video_preview.png'
import {PlayCircle} from "@styled-icons/bootstrap/PlayCircle"

const SectionDescription: React.FC = () => {
  const [isStartVideo, setIsStartVideo] = React.useState(false)

  const handleStartVideo = () => {
    const video: HTMLVideoElement = document.getElementById("descriptionVideo") as HTMLVideoElement;
    video.play();
    setIsStartVideo(true)
  }

  return (
    <SectionMax customStyles='padding: 0;'>
        <ContainerDescription>
            <div>
                <Typography variant="h2" component="h2">Descripción del Proceso:</Typography>
                <Typography variant="body1" component="p">Una guía paso a paso para la inserción y extracción de Engyno<sup>®</sup>1, demostrando su facilidad de uso y el proceso profesional recomendado.</Typography>
            </div>
            <div>
                {/* <VideoPlayer {...videoProps} /> */}
                <video id="descriptionVideo" src={VideoMP4} controls></video>
                <ContainerPreview isStartVideo={isStartVideo}>
                  <img src={VideoPreview} alt="preview"/>
                  <div>
                    <PlayCircle onClick={handleStartVideo}/>
                  </div>
                </ContainerPreview>
            </div>
        </ContainerDescription>
    </SectionMax>
  )
}

export default SectionDescription