import React from 'react'
import { ContainerFunctionality } from './section-functionality-styles'
import SectionMax from '../../../../components/section-max/section-max'
import { Box, Typography } from '@mui/material'
import FunctionalityIMG from '../../../../assets/img/img_04.png'

const SectionFunctionality: React.FC = () => {
  return (
    <SectionMax customStyles='padding: 0;'>
        <ContainerFunctionality id="funcionalidad">
          <div>
              <Box
                  component={"img"}
                  alt="Engyno1"
                  src={FunctionalityIMG}
              />
          </div>
          <div>
              <Typography variant="h2" component="h2">¿Cómo funciona Engyno<sup>®</sup>1?</Typography>
              <Typography variant="body2" component="p">Engyno<sup>®</sup>1 actúa liberando una dosis baja de levonorgestrel directamente en el útero, lo que previene la proliferación del endometrio y engrosa el moco cervical, inhibiendo el paso de los espermatozoides.</Typography>
          </div>
        </ContainerFunctionality>
    </SectionMax>
  )
}

export default SectionFunctionality