import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerDescription = styled.div`
place-content: center;
padding: 60px 0;
display: grid;
width: 100%;

> div:nth-child(1) {

    > h2{
        font-weight: 500;
        font-size: 50px;
        color: #F06125;
        width: 100%;
        margin: 0;
        padding: 0;

        ${breakpoints.laptopM}{
            font-size: 35px;
        }
        
        ${breakpoints.tabletL}{
          font-size: 30px;
        }
        ${breakpoints.tabletM}{
          font-size: 22px;
        }
    }

    
  > p {
    font-size: 28px;
    font-weight: 500;
    margin-top: 10px;
    text-align: justify;

    ${breakpoints.laptopM}{
      font-size: 25px;
    }

    ${breakpoints.tabletL}{
      font-size: 22px;
    }
    ${breakpoints.tabletM}{
      font-size: 18px;
    }
  }
}

> div:nth-child(2) {
    text-align: center;
    position: relative;

    > video{
        width: 100%;
        margin-top: 40px;
    }
}

${breakpoints.laptopM}{
    padding: 60px 30px;
}
`

export const ContainerPreview = styled.div<{ isStartVideo: boolean }>`
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0; 
  left: 0;
  top: 0;
  
  > img{
    height: 100%;
    width: 100%;
    border-radius: 6px;
  }
  
  > div {
    justify-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    height: 100%;
    margin: auto;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    
    > svg{
      max-width: 80px;
    }
    
  }
  
  > div:hover{
    background: #000000ad;
    transition: 0.5s;
    
    > svg{
      transform: scale(1.5);
      transition: 0.4s;
      cursor: pointer;
      color: white;
    }
  }
  
  ${p => p.isStartVideo ? `
    width: 0px;
    height: 0px;
    display: none;

    > *, div, img, svg {
      width: 0px;
      height: 0px;
      display: none;
      overflow: hidden;
    }
  ` : ""}
`

export const ContainerVideo = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  width: 100%;

  > div {
    width: 100% !important;
    height: 600px !important;

    ${breakpoints.tabletL} {
      height: 400px !important;
    }

    ${breakpoints.tabletM} {
      height: 300px !important;
    }

    ${breakpoints.phoneL} {
      height: 200px !important;
    }
  }
`;