import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerTestimonials = styled.div`
background: radial-gradient(circle, rgba(255,255,255,1) -40%, rgba(255,224,177,1) 100%);
padding: 0;

> div div {
    > h2 {
        font-weight: 500;
        font-size: 50px;
        color: #F06125;
        width: 100%;
        margin: 0;
        padding: 0;

        ${breakpoints.laptopM}{
            font-size: 35px;
        }

        ${breakpoints.tabletL}{
          font-size: 30px;
        }
        ${breakpoints.tabletM}{
          font-size: 22px;
        }
    }

    > p{
        font-size: 28px;
        font-weight: 500;
        text-align: justify;
        
        > sup{
            font-size: 15px;
        }

        ${breakpoints.laptopM}{
            font-size: 25px;
        }
      
        ${breakpoints.tabletL}{
            font-size: 22px;
        }
        ${breakpoints.tabletM}{
            font-size: 18px;
        }
    }
}

${breakpoints.laptopM}{
    padding: 0 10px;
}
`

export const ListTestimonials = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-top: 40px;

${breakpoints.tabletM}{
    justify-content: space-around;
    gap: 20px;
}
`

export const ItemTestimonial = styled.div`
box-sizing: border-box;
border: 1px solid blue;
justify-content: center;
align-items: center;
position: relative;
border-radius: 50%;
display: flex;
height: 300px;
padding: 10px;
width: 300px;

> div {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #F06725;
    border-radius: 50%;
    padding: 40px;
    display: flex;
    height: 100%;
    color: white;
    width: 100%;
    gap: 15px;

    > p:nth-child(1) {
        font-size: 15px;
        font-weight: 500;
        text-align: center;

        ${breakpoints.laptopM}{
            font-size: 14px;
        }
        
        ${breakpoints.tabletL}{
            font-size: 11px;
        }
        
        ${breakpoints.phoneL}{
            font-size: 12px;
        }
    }
    > p:nth-child(2) {
        font-size: 16px;
        font-weight: 800;

        ${breakpoints.laptopM}{
            font-size: 15px;
        }
        
        ${breakpoints.tabletL}{
            font-size: 14px;
        }
        ${breakpoints.phoneL}{
            font-size: 14px;
        }
    }

    ${breakpoints.tabletM}{
        padding: 20px;
    }
    
    ${breakpoints.phoneL}{
        padding: 25px;
    }
}

> div img {
    position: absolute;
    top: 0;
    width: 20%;
    left: 0;
}

${breakpoints.laptopM}{
    height: 280px;
    width: 280px;
}

${breakpoints.tabletL}{
    height: 215px;
    width: 215px;
}

${breakpoints.phoneL}{
    height: 220px;
    width: 220px;
    padding: 5px;
}
`