import React from "react";
import { ButtonNav } from "./item-nav-mobile-styles";
import { Typography } from "@mui/material";

interface IOWnProps {
  text: string;
  to: string;
}

const ItemNavMobile: React.FC<IOWnProps> = ({ text, to }) => {
  return (
    <ButtonNav href={`#${to}`}>
       {text=="Engyno" ? (
        <Typography
        variant="caption"
        component="span"
        sx={{ letterSpacing: "0.5px", fontSize: "0.8rem", "span":{ verticalAlign: "top", fontSize: "10px"} }}
        >
        Engyno<sup>®</sup>1
      </Typography>
      ): (
        
        <Typography
        variant="caption"
        component="span"
        sx={{ letterSpacing: "0.5px", fontSize: "0.8rem" }}
        >
        {text}
      </Typography>
      )}
    </ButtonNav>
  );
};

export default ItemNavMobile;
