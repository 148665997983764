import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerContactus = styled.div`
  text-align: center;
  padding: 60px 0 60px;

  ${breakpoints.tabletM}{
    padding: 60px 10px 60px;
  }
`;
export const ContainerMainTitle = styled.div`
  position: relative;
  padding: 0px 0 60px;
  width: fit-content;
  margin: auto;
  width: 100%;
`;

export const ContainerText = styled.div`
  width: 100%;
  color: #F06125;

  > span {
    font-weight: bold;
    font-size: 4rem;
  }

  ${breakpoints.tabletL} {
    > span {
      font-size: 3.6rem;
    }
  }
  ${breakpoints.tabletM} {
    > span {
      font-size: 3rem;
    }
  }
  ${breakpoints.phoneL} {
    width: 100%;
    text-align: left;
    
    > span {
      font-size: 1.8rem;
    }
  }

  ${breakpoints.phoneM} {
    > span {
      font-size: 1.2rem;
    }
  }
`;

export const ContainerForm = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  gap: 20px;
`;

export const ItemInput = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: auto;
  width: 100%;

  > span {
    width: 50%;
    text-align: left;
    color: red;
    margin-top: 5px;
  }

  > input,
  textarea {
    border: double 1px #F06125;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    resize: none;
    width: 50%;

    ${breakpoints.tabletM} {
      width: 100%;
    }
  }
`;

export const ContainerButton = styled.div`
  width: 50%;
  margin: auto;
  padding: 20px 0 0;

  > div {
    border: double 1px #F06125;
    border-radius: 5px;
    width: 100%;
    padding: 2px;

    > button {
      width: 100%;
      background: #F06125;
      outline: none;
      color: white;
      border: 0;
      padding: 10px 15px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      font-size: 30px;
      font-weight: 400;
      cursor: pointer;

      > svg {
        max-width: 30px;
        width: 100%;
      }

      ${breakpoints.tabletL} {
        font-size: 25px;
      }

      ${breakpoints.phoneL} {
        font-size: 18px;
      }
    }
  }

  ${breakpoints.tabletM} {
    width: 100%;
  }
`;
