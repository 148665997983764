import React from 'react'
import SectionMax from '../../../../components/section-max/section-max'
import { Box, Typography } from '@mui/material'
import { ContainerTestimonials, ItemTestimonial, ListTestimonials } from './section-testimonials-styles'
import IconTestimonial from '../../../../assets/img/icon_testimonial.png'

const SectionTestimonials: React.FC = () => {
  return (
    <ContainerTestimonials id="testimonios">
        <SectionMax>
            <div>
                <Typography variant="h2" component="h2">Testimonios</Typography>
                <Typography variant="body1" component="p">Historias reales de mujeres que han experimentado los beneficios de Engyno<sup>®</sup>1 en su vida diaria. Descubre cómo Engyno<sup>®</sup>1 ha mejorado su calidad de vida.</Typography>
            </div>
            <ListTestimonials>
                <ItemTestimonial>
                    <div>
                        <Typography variant="body2" component="p">Mi menstruación se hizo mas ligera y sin los molestos colicos, hay meses que no reglo, me encanta.</Typography>
                        <Typography variant="body2" component="p">Alejandra</Typography>
                        <Box
                            component={"img"}
                            alt="Engyno1"
                            src={IconTestimonial}
                        />
                    </div>
                </ItemTestimonial>
                <ItemTestimonial>
                    <div>
                        <Typography variant="body2" component="p">Me lo retiré después de 4 años porque queria embarazarme y 6 meses despues lo logré sin problema.</Typography>
                        <Typography variant="body2" component="p">Lorena</Typography>
                        <Box
                            component={"img"}
                            alt="Engyno1"
                            src={IconTestimonial}
                        />
                    </div>
                </ItemTestimonial>
                <ItemTestimonial>
                    <div>
                        <Typography variant="body2" component="p">Quiero tener hijos pero aún no es el momento por eso consulté con mi médico y confiamos en este método.</Typography>
                        <Typography variant="body2" component="p">Carmen</Typography>
                        <Box
                            component={"img"}
                            alt="Engyno1"
                            src={IconTestimonial}
                        />
                    </div>
                </ItemTestimonial>
            </ListTestimonials>
        </SectionMax>
    </ContainerTestimonials>
  )
}

export default SectionTestimonials