import React from 'react'
import { Box, Typography } from '@mui/material'
import { ContainerBenefits, ContainerLeft, ContainerList, ContainerRight, ContainerStylesMax, FloatBackground, DividerLeft, DividerRight, ItemList } from './section-benefits-styles'
import WoomanIMG from '../../../../assets/img/img_03.png'
import SectionMax from '../../../../components/section-max/section-max'
import Step00IMG from '../../../../assets/img/step_00.png'
import Step01IMG from '../../../../assets/img/step_01.png'
import Step02IMG from '../../../../assets/img/step_02.png'
import Step03IMG from '../../../../assets/img/step_03.png'
import RompecabezasIMG from '../../../../assets/img/rompecabezas-header.png'

const SectionBenefits: React.FC = () => {
  return (
    <ContainerBenefits imgBack={RompecabezasIMG}>
      <DividerLeft />
      <SectionMax customStyles={ContainerStylesMax}>
        <ContainerLeft>
          <Typography component="h2" variant="h2">Beneficios</Typography>
          <ContainerList>
            <ItemList>
              <div>
                <Box
                  component={"img"}
                  alt="Engyno1"
                  src={Step00IMG}
                />
                <Typography variant="h5" component="h5">Anticoncepción de larga duración: <span>hasta 6 años de protección.</span></Typography>
              </div>
              <div>
                <div>
                  <Box
                    component={"img"}
                    alt="Engyno1"
                    src={Step01IMG}
                  />
                </div>
              </div>
            </ItemList>
            <ItemList>
              <div>
                <Box
                  component={"img"}
                  alt="Engyno1"
                  src={Step00IMG}
                />
                <Typography variant="h5" component="h5">Tratamiento efectivo <span>para la menorragia idiopática</span></Typography>
              </div>
              <div>
                <div>
                  <Box
                    component={"img"}
                    alt="Engyno1"
                    src={Step02IMG}
                  />
                </div>
              </div>
            </ItemList>
            <ItemList>
              <div>
                <Box
                  component={"img"}
                  alt="Engyno1"
                  src={Step00IMG}
                />
                <Typography variant="h5" component="h5">Protección contra la hiperplasia endometrial <span>durante el tratamiento de sustitución de estrógenos.</span></Typography>
              </div>
              <div>
                <div>
                  <Box
                    component={"img"}
                    alt="Engyno1"
                    src={Step03IMG}
                  />
                </div>
              </div>
            </ItemList>
          </ContainerList>
        </ContainerLeft>
        <ContainerRight>
          <Box
            component={"img"}
            alt="Engyno1"
            src={WoomanIMG}
            />
            <FloatBackground />
        </ContainerRight>
      </SectionMax>
      <DividerRight />
    </ContainerBenefits>
  )
}

export default SectionBenefits;