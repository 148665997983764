import styled from "styled-components";

export const ContainerSignIn = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  display: flex;
  height: 100vh;
  width: 100vw;
  gap: 40px;

  > div:nth-child(1) img {
    width: 100%;
    max-width: 200px;
  }

  > form {
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    background: #f9f9f9;
    max-width: 300px;
    padding: 30px;
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 20px;

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      > label {
        font-size: 14px;
      }

      > input {
        border: 1px solid #c9c9c9;
        padding: 8px 10px;
        border-radius: 6px;
        outline: none;
        width: 100%;
      }
    }

    > button {
      background: #f06125;
      border-radius: 6px;
      transition: 0.5s;
      padding: 10px;
      outline: none;
      border: none;
      color: white;

      &:hover {
        background: #d9551d;
        cursor: pointer;
      }
    }
  }
`;
