import React from 'react'
import { Box, Typography } from '@mui/material'
import { ContainerBenefits, ContainerLeft, ContainerRight, ContainerStylesMax, FloatBackground, DividerLeft, DividerRight, ContainerButtons } from './section-engyno-styles'
import WoomanIMG from '../../../../assets/img/img_06.png'
import SectionMax from '../../../../components/section-max/section-max'
import RompecabezasIMG from '../../../../assets/img/rompecabezas-header.png'
import LogoTextIMG from '../../../../assets/img/logo_text.png'
import backImage from '../../../../assets/img/background_button.png'

const SectionEngyno: React.FC = () => {
  return (
    <ContainerBenefits imgBack={RompecabezasIMG}>
      <DividerLeft />
      <SectionMax customStyles={ContainerStylesMax}>
      <ContainerLeft>
            <Box
              component={"img"}
              alt="Engyno1"
              src={WoomanIMG}
            />
            <FloatBackground />
        </ContainerLeft>
        <ContainerRight>
          <div>
            <Box
              component={"img"}
              alt="Engyno1"
              src={LogoTextIMG}
            />
          </div>
          <div>
            <Typography component="h2" variant="h2">Consulta a tu médico hoy para saber si Engyno<sup>®</sup>1 es la opción adecuada para ti. ¡Cuida tu cuerpo, vive sin preocupaciones!</Typography>
          </div>
          {/* <ContainerButtons>
            <button>
              Contacta a un expecialista
              <Box
                component={"img"}
                alt="Engyno1"
                src={backImage}
              />
            </button>
            <button>
              Encuentra un distribuidor
              <Box
                component={"img"}
                alt="Engyno1"
                src={backImage}
              />
            </button>
          </ContainerButtons> */}
        </ContainerRight>
      </SectionMax>
      <DividerRight />
    </ContainerBenefits>
  )
}

export default SectionEngyno;