import React from "react";
import SectionHeader from "./sections/section-header/section-header";
import ContactUs from "./sections/contact-us/contact-us";
import MainPageLayout from "../../components/mainpage-layout/mainpage-layout";
import SectionInfo from "./sections/section-info/section-info";
import SectionBenefits from "./sections/section-benefits/section-benefits";
import SectionFunctionality from "./sections/section-functionality/section-functionality";
import SectionDescription from "./sections/section-description/section-description";
import SectionTestimonials from "./sections/section-testimonials/section-testimonials";
import SectionFaqs from "./sections/section-faqs/section-faqs";
import SectionEngyno from "./sections/section-engyno/section-engyno";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { APP_CONSTANS } from "../../constants/app";

const MainPage: React.FC = () => {
  const keyAuth = localStorage.getItem("keyweb");
  const navigate = useNavigate();

  React.useEffect(() => {
    const keyEncrypt = Cookies.get(APP_CONSTANS.KAEG1CT);
    if (!!keyEncrypt) {
      const tokenDecrypt = CryptoJS.AES.decrypt(
        keyEncrypt,
        APP_CONSTANS.KAEG1CT
      ).toString(CryptoJS.enc.Utf8);
      if (!(tokenDecrypt == APP_CONSTANS.KEYENGYNO)) {
        navigate("/sign-in");
      }
    } else {
      navigate("/sign-in");
    }
  }, []);

  return (
    <MainPageLayout>
      <SectionDescription />
      <SectionBenefits />
      <SectionHeader />
      <SectionInfo />
      <SectionFunctionality />
      <SectionTestimonials />
      <SectionFaqs />
      <SectionEngyno />
      <ContactUs />
    </MainPageLayout>
  );
};

export default MainPage;
