import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerFunctionality = styled.div`
justify-content: space-between;
flex-direction: row;
align-items: center;
padding: 60px 0;
display: flex;
gap: 20px;

> div:nth-child(1), div:nth-child(2){
  width: 50%;

  ${breakpoints.tabletM}{
    width: 100%;
  }
}

> div:nth-child(1){
  img{
    width: 100%;
  }
}


> div h2{
    font-weight: 500;
    font-size: 50px;
    color: #F06125;
    width: 100%;
    
    > span {
      font-weight: 800;
    }

    > sup {
      vertical-align: top;
      font-size: 25px;

      ${breakpoints.tabletL}{
        font-size: 20px;
      }
      
      ${breakpoints.tabletM}{
        font-size: 15px;
      }
    }

    ${breakpoints.laptopM}{
      font-size: 35px;
    }
    
    ${breakpoints.tabletL}{
      font-size: 30px;
    }
    ${breakpoints.tabletM}{
      font-size: 22px;
    }
  }

  > div p {
    font-size: 28px;
    font-weight: 500;
    margin-top: 30px;
    text-align: justify;

    > sup {
      vertical-align: top;
      font-size: 15px;

      ${breakpoints.phoneL}{
        font-size: 12px;
      }
    }

    ${breakpoints.laptopM}{
      font-size: 25px;
    }

    ${breakpoints.tabletL}{
      font-size: 22px;
    }

    ${breakpoints.tabletM}{
      font-size: 18px;
    }
  }

  ${breakpoints.laptopM}{
    padding: 60px 20px;
  }
  
  ${breakpoints.tabletM}{
    flex-direction: column;
    padding: 60px 30px;
  }
`