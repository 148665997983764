import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MainCampaignIMG from "../../../assets/img/campaign_main.jpg";
import { RootState } from "../store";

export interface LandingState {
  isLoadingApp: boolean;
  idViewIcon: number;
  isAuthDevice: boolean;
}

const initialState: LandingState = {
  isLoadingApp: false,
  idViewIcon: 0,
  isAuthDevice: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateLoadingApp: (state, action: PayloadAction<boolean>) => {
      state.isLoadingApp = action.payload;
    },
    updateViewIcon: (state, action: PayloadAction<number>) => {
      state.idViewIcon =
        action.payload == state.idViewIcon ? 0 : action.payload;
    },
    updateAuthDevice: (state, action: PayloadAction<boolean>) => {
      state.isAuthDevice = action.payload;
    },
  },
});

export const { updateLoadingApp, updateViewIcon, updateAuthDevice } =
  appSlice.actions;

export const getIsLoadingApp = (state: RootState) => state.app.isLoadingApp;
export const getIdViewIcon = (state: RootState) => state.app.idViewIcon;
export const getIsAuthDevice = (state: RootState) => state.app.isAuthDevice;

export default appSlice.reducer;
