import { styled } from "styled-components";
import { customPalette } from "../../config/theme/theme";
import { breakpoints } from "../../constants/breakpoints";

export const ContainerNavbar = styled.div`
background: white;
position: relative;
height: 200px;

${breakpoints.tabletM}{
  height: 150px;
}
`

export const FloatColor = styled.div<{ imgBack: string }>`
clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);
background-image: url(${p => p.imgBack});
background-blend-mode: color-burn;
background-repeat: no-repeat;
background-position: 0% 58%;
background-color: #F06125;
background-size: 200%;
position: absolute;
height: 100%;
z-index: 0;
width: 60%;
right: 0;
top: 0;

${breakpoints.tabletL}{
  width: 70%;
}

${breakpoints.tabletM}{
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
  width: 40%;
}
`

export const ContainerGeneral = styled.div`
justify-content: space-between;
flex-direction: row;
position: absolute;
display: flex;
height: 100%;
width: 100%;
z-index: 1;
bottom: 0;
right: 0;
left: 0;
top: 0;
`

export const ContainerLeft = styled.div`
background-color: white;
justify-content: left;
align-items: center;
display: flex;
padding: 10px;
width: 35%;

> img {
  max-width: 360px;
  width: 100%;
}

${breakpoints.tabletL}{
  width: 28%;
}

${breakpoints.tabletM}{
  width: 60%;
}
`

export const ContainerRight = styled.div<{ imgBack: string }>`
background: transparent;
justify-content: center;
align-items: flex-end;
padding: 20px 20px 50px;
display: flex;
width: 60%;

${breakpoints.tabletL}{
  width: 68%;
}

${breakpoints.tabletM}{
  width: 35%;
  align-items: center;
  padding: 20px;
}
`

export const ContainerNavLinks = styled.div`
  justify-content: space-between;
  align-items: top;
  display: flex;
  width: 100%;

  ${breakpoints.tabletM} {
    display: none;
  }
`;

export const ContainerBar = styled.div`
  cursor: pointer;
  display: none;

  > svg {
    max-width: 26px;
    color: white;
    width: 100%;
  }

  ${breakpoints.tabletM} {
    grid-column: 16 / span 1;
    place-items: center;
    display: grid;
  }
`;

export const BottomNavbarMobile = styled.div<{ isActive: boolean }>`
  background: ${customPalette.primaryColor};
  position: absolute;
  padding: 2rem 0 1rem;
  transition: 0.5s;
  width: 100%;
  z-index: 1;
  left: 0;

  height: ${(p) => (p.isActive ? "fit-content" : "0px")};
  transform: ${(p) => (p.isActive ? "translateY(0%)" : "translateY(-150%)")};

  ${(p) =>
    p.isActive
      ? ""
      : `
      display: none;
  `}
`;

