import React from "react";
import { createBrowserRouter } from "react-router-dom";
import MainPage from "../../features/main-page/main-page";
import SignIn from "../../features/sign-in/sign-in";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
]);
