import React from 'react'
import { Box, Typography } from '@mui/material'
import { ContainerFaqs, ContainerLeft, ContainerList, ItemList } from './section-faqs-styles'
import WoomanIMG from '../../../../assets/img/img_03.png'
import SectionMax from '../../../../components/section-max/section-max'
import Step00IMG from '../../../../assets/img/step_00.png'
import Step01IMG from '../../../../assets/img/step_01.png'
import Step02IMG from '../../../../assets/img/step_02.png'
import Step03IMG from '../../../../assets/img/step_03.png'
import RompecabezasIMG from '../../../../assets/img/rompecabezas-header.png'

const SectionFaqs: React.FC = () => {
  return (
    <ContainerFaqs id="preguntas-frecuentes">
      <SectionMax customStyles='padding: 0;'>
        <ContainerLeft>
          <Typography component="h2" variant="h2">FAQs</Typography>
          <ContainerList>
            <ItemList>
              <div>
                <Box
                  component={"img"}
                  alt="Engyno1"
                  src={Step00IMG}
                />
                <div>
                  <div>
                    <Box
                      component={"img"}
                      alt="Engyno1"
                      src={Step00IMG}
                    />
                    <Typography variant="h5" component="h5">¿Qué es Engyno<sup>®</sup>1 y para qué se utiliza?</Typography>
                  </div>
                  <Typography variant="body1" component="p">Engyno<sup>®</sup>1 es un sistema de liberación intrauterino (SLI) para su inserción en el útero, donde libera lentamente la hormona levonorgestrel. Es un método anticonceptivo eficaz, a largo plazo no permanente.</Typography>
                </div>
              </div>
            </ItemList>
            <ItemList>
              <div>
                <Box
                  component={"img"}
                  alt="Engyno1"
                  src={Step00IMG}
                />
                <div>
                  <div>
                    <Box
                      component={"img"}
                      alt="Engyno1"
                      src={Step00IMG}
                      />
                    <Typography variant="h5" component="h5">¿Cómo funciona?</Typography>
                  </div>
                  <Typography variant="body1" component="p">Engyno<sup>®</sup>1 evita el embarazo al adelgazar el revestimiento de la matriz (útero), haciendo más espeso el moco normal de la abertura del útero (canal cervical), de modo que los espermatozoides no pueden atravesarlo para fecundar el óvulo, y evitando la liberación de los óvulos (ovulación) en algunas mujeres. Además, la presencia del cuerpo en forma de T provoca efectos locales en el revestimiento del útero.</Typography>
                </div>
              </div>
            </ItemList>
            <ItemList>
              <div>
                <Box
                  component={"img"}
                  alt="Engyno1"
                  src={Step00IMG}
                />
                <div>
                  <div>
                    <Box
                      component={"img"}
                      alt="Engyno1"
                      src={Step00IMG}
                      />
                    <Typography variant="h5" component="h5">¿Cuánto dura?</Typography>
                  </div>
                  <Typography variant="body1" component="p">Este medicamento debe retirarse después de 6 años de uso cuando se utiliza como anticonceptivo.</Typography>
                </div>
              </div>
            </ItemList>
            <ItemList>
              <div>
                <Box
                  component={"img"}
                  alt="Engyno1"
                  src={Step00IMG}
                />
                <div>
                  <div>
                    <Box
                      component={"img"}
                      alt="Engyno1"
                      src={Step00IMG}
                      />
                    <Typography variant="h5" component="h5">Tratamiento del sangrado menstrual abundante</Typography>
                  </div>
                  <Typography variant="body1" component="p">Engyno<sup>®</sup>1 también es útil para reducir el flujo sanguíneo menstrual, de modo que lo puede usar si sufre sangrado menstrual (periodos) abundante. Esto se denomina menorragia. La hormona en Levosert actúa adelgazando el revestimiento de su útero para que haya menos sangrado cada mes.</Typography>
                </div>
              </div>
            </ItemList>
          </ContainerList>
        </ContainerLeft>
      </SectionMax>
    </ContainerFaqs>
  )
}

export default SectionFaqs;